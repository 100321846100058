import React from 'react';

export default function Footer( props ) {

  return (
    <footer className="p-4 pb-5 md:rounded-lg md:flex md:items-center md:justify-between md:bg-gray-800 md:p-6 mx-auto w-full md:w-6/12">
        <div className="text-sm mx-auto md:mx-0 text-center text-gray-500">
            Powered By <span className='text-orange-500'>@PayUnit</span>
        </div>
        <ul className="flex flex-wrap items-center justify-center mt-4 text-sm text-gray-500 dark:text-black-400 sm:mt-0">
            <li>
                <a href="#test" className="mr-4 hover:underline md:mr-6 ">About</a>
            </li>
            <li>
                <a href="#test" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
            </li>
            <li>
                <a href="#test" className="hover:underline">Contact</a>
            </li>
        </ul>
    </footer>
  )
}


