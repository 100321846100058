import React, { useState } from 'react'
import VisaLogos from './../../../assets/images/payment/visa-cards.png'
import CountrySelector from '../CountrySelect'
import { COUNTRIES } from '../../../utils/allCountries'

export default function BankPaymentForm({ formik }) {

    const [ countryOpen, setCountryOpen ] = useState(false)

    const onCountryChange = (value) => {
        const selectedCountry = COUNTRIES.find((option) => option.value === value)
        formik.setFieldValue('cardCountry', selectedCountry)
    }

    return (
        <>
            <hr className="sm:mx-auto border-gray-200 my-5" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-5">       
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardNumber">
                        Card number <sup className='text-red-500'>*</sup>
                    </label>
                    
                    <div className='flex justify-center items-center rounded border leading-tight'>
                        <input 
                            className="appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            onChange={ formik.handleChange }
                            onBlur={ formik.handleBlur }
                            value={ formik.values.cardNumber }
                            autoCorrect='off'
                            type='text'
                            autoCapitalize='none'
                            id='cardNumber'
                            name='cardNumber'
                            placeholder="xxxx xxxx xxxx xxxx"
                            required={ true }
                        />
                        <label htmlFor="cardNumber">
                            <div className='text-center py-2'>
                                <img src={ VisaLogos } className='mx-auto' alt='test'/>
                            </div>
                        </label>
                    </div>
                    { formik.touched.cardNumber && formik.errors.cardNumber && <div className='italic' style={{ color: 'red' }}> {formik.errors.cardNumber} </div> }
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cvv">
                        CVV <sup className='text-red-500'>*</sup>
                    </label>
                    <input 
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.cvv }
                        autoCorrect='off'
                        type='text'
                        autoCapitalize='none'
                        maxLength={3}
                        id='cvv'
                        name='cvv'
                        placeholder="xxx"
                        required={ true }
                    />
                    { formik.touched.cvv && formik.errors.cvv && <div className='italic' style={{ color: 'red' }}> {formik.errors.cvv} </div> }
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expirationMonth">
                        Expiration date <sup className='text-red-500'>*</sup>
                    </label>
                    <div className='flex'>
                        <input 
                            className="appearance-none border rounded w-20 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            onChange={ formik.handleChange }
                            onBlur={ formik.handleBlur }
                            value={ formik.values.expirationMonth }
                            autoCorrect='off'
                            type='number'
                            autoCapitalize='none'
                            id='expirationMonth'
                            name='expirationMonth'
                            placeholder="MM"
                            min={1}
                            max={12}
                            required={ true }
                        />
                        <div className='pt-2 px-2 text-gray-400'>/</div>
                        <input 
                            className="appearance-none border rounded w-20 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            onChange={ formik.handleChange }
                            onBlur={ formik.handleBlur }
                            value={ formik.values.expirationYear }
                            autoCorrect='off'
                            type='number'
                            autoCapitalize='none'
                            id='expirationYear'
                            name='expirationYear'
                            placeholder="YY"
                            required={ true }
                        />
                    </div>
                    { formik.touched.expirationMonth && formik.errors.expirationMonth && <div className='italic' style={{ color: 'red' }}> {formik.errors.expirationMonth} </div> }
                </div>


                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zipCode">
                        Zip code <sup className='text-red-500'>*</sup>
                    </label>
                    <input 
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.zipCode }
                        autoCorrect='off'
                        type='text'
                        autoCapitalize='none'
                        id='zipCode'
                        name='zipCode'
                        placeholder="ZipCode"
                        required={ true }
                    />
                    { formik.touched.zipCode && formik.errors.zipCode && <div className='italic' style={{ color: 'red' }}> {formik.errors.zipCode} </div> }
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardCountry">
                        Card country <sup className='text-red-500'>*</sup>
                    </label>
                    <CountrySelector
                        id="cardCountry"
                        open={ countryOpen }
                        onToggle={() => setCountryOpen(!countryOpen)}
                        onChange={(value) => onCountryChange(value)}
                        selectedValue={ formik.values.cardCountry }
                    />
                    { formik.touched.cardCountry && formik.errors.cardCountry && <div className='italic' style={{ color: 'red' }}> {formik.errors.cardCountry} </div> }
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardState">
                        Card state <sup className='text-red-500'>*</sup>
                    </label>
                    <input 
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.cardState }
                        autoCorrect='off'
                        type='text'
                        autoCapitalize='none'
                        id='cardState'
                        name='cardState'
                        placeholder="State"
                        required={ true }
                    />
                    { formik.touched.cardState && formik.errors.cardState && <div className='italic' style={{ color: 'red' }}> {formik.errors.cardState} </div> }
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardCity">
                        Card city <sup className='text-red-500'>*</sup>
                    </label>
                    <input 
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.cardCity }
                        autoCorrect='off'
                        type='text'
                        autoCapitalize='none'
                        id='cardCity'
                        name='cardCity'
                        placeholder="City"
                        required={ true }
                    />
                    { formik.touched.cardCity && formik.errors.cardCity && <div className='italic' style={{ color: 'red' }}> {formik.errors.cardCity} </div> }
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardAddress">
                        Card address <sup className='text-red-500'>*</sup>
                    </label>
                    <input 
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.cardAddress }
                        autoCorrect='off'
                        type='text'
                        autoCapitalize='none'
                        id='cardAddress'
                        name='cardAddress'
                        placeholder="address"
                        required={ true }
                    />
                    { formik.touched.cardAddress && formik.errors.cardAddress && <div className='italic' style={{ color: 'red' }}> {formik.errors.cardAddress} </div> }
                </div>
                
            </div>
        </>
    )
}
