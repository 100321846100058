import React, { useState } from "react";
import { toast } from "react-toastify";
import { LoaderShipping } from "../LoaderGroup";
import ShippingAddress from "../ShippingAddress";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Label } from "reactstrap";
import PaymentInput from "../PaymentInput";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import Select from "react-select";
import { countryValues } from "../../../utils";
import { useSelector } from "react-redux";
import PayUnitLogo from "./../../../assets/images/logo/logo.png";
import BankPaymentForm from "../BankPaymentForm";

export default function PaymentForm({ formik, isLoading, isProcessing, paymentInputs, onPaymentCountryChange, loadingPsp }) {
  const productList = useSelector((store) => store.datas.productList);
  const currency = useSelector((store) => store.datas.currency);
  const actualTotal = useSelector((store) => store.datas.actualTotal);
  const providers = useSelector((store) => store.datas.providers);

  const availableCountries = [];

  countryValues?.map((country) => {
    return providers?.map((psp) => {
      // Affiche uniquement les pays des providers retournés par l'API
      if (
        psp.country.country_code === country.value &&
        availableCountries.findIndex((item) => item.value === country.value) ===
          -1
      ) {
        availableCountries.push(country);
      }
      return true;
    });
  });

  const [showAdressInfo, setShowAdressInfo] = useState(false);

  // Option pour le paiement avec paypal
  const paypalOptions = {
    "client-id":
      "Ab9pP4kv6q2NVaXT0F-_ar6hBqVEck8vq2Vhh9-3BfHZlaOFNoiyNDySJInLAv2CfKfdi1rWgiDwl1LV",
    currency: currency,
    intent: "capture",
  };


  return (
    <section className="lg:p-5 h-auto">
      {/* Logo payunit */}
      <div className="text-center">
        <img
          className="mx-auto hidden lg:block"
          src={PayUnitLogo}
          width={150}
          alt="test"
        />
      </div>
      {isLoading || productList?.length === 0 ? (
        <LoaderShipping />
      ) : (
        <div className="overflow-auto scrollbar scrollbar-thumb-gray-200 scrollbar-track-gray-100">
          <form className="px-8 pt-6 pb-8 mb-4" onSubmit={formik.handleSubmit}>
            {/* Methode de paiement en fonction du pays */}
            <div className="mb-5">
              <Label className="block form-label text-gray-700 text-sm font-bold mb-2">
                Payment method <sup className="text-red-500">*</sup>
              </Label>
              <div className="mb-5">
                {/* Pays de la methode de paiement */}
                {availableCountries && (
                  <Select
                    aria-label="tests"
                    defaultValue={formik.values.country}
                    name="country"
                    onChange={(country) => onPaymentCountryChange(country)}
                    options={availableCountries}
                    className="w-100 mb-1"
                    required={true}
                  />
                )}
                {formik.touched.country && formik.errors.country && (
                  <div className="italic" style={{ color: "red" }}>
                    {" "}
                    {formik.errors.country}{" "}
                  </div>
                )}
              </div>
              {/* Liste des methodes de paiement */}
              {formik.values.country.value !== "" &&
                (paymentInputs?.length > 0 ? 
                  (loadingPsp ?
                    <div className='mx-auto py-5 my-3 w-full text-center'>
                      <svg aria-hidden="true" role="status" className="inline w-5 h-5 mb-1 animate-spin mx-auto" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="gray"/>
                      </svg>
                      <div className="text-gray-500">loading providers</div>
                    </div> 
                  :
                    <div className="items-center grid grid-cols-4 gap-2">
                      {paymentInputs?.map((payment, key) => (
                        <PaymentInput
                          formik={formik}
                          payment={payment}
                          key={key}
                        />
                      ))}
                    </div>
                  ) 
                : 
                  <div className="italic text-red-500 font-bold text-center w-full">
                    No provider available in your country for this checkout
                    session.
                  </div>
                )}
            </div>

            {/* Formulaire de paiement bancaire, si le user a choisi World Visa comme methode de paiement */}
            {formik.values.paymentMethod === "WORLD_VISA" && (
              <BankPaymentForm formik={formik} />
            )}

            {/* Numero mobile money du payeur, si la methode de paiement choisie est un provider mobile money */}
            {formik.values.paymentMethod !== "WORLD_PAYPAL" &&
              formik.values.paymentMethod !== "WORLD_VISA" && (
                <div className="my-5">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="phonePayment"
                  >
                    Payment phone number <sup className="text-red-500">*</sup>
                  </label>
                  <input
                    className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phonePayment}
                    autoCorrect="off"
                    type="text"
                    autoCapitalize="none"
                    id="phonePayment"
                    name="phonePayment"
                    placeholder="Phone number"
                    required={true}
                  />
                  {formik.touched.phonePayment &&
                    formik.errors.phonePayment && (
                      <div className="italic" style={{ color: "red" }}>
                        {" "}
                        {formik.errors.phonePayment}{" "}
                      </div>
                    )}
                </div>
              )}

            <hr className="sm:mx-auto border-gray-200 my-5" />
        

            {/* Email input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email <sup className="text-red-500">*</sup>
              </label>
              <input
                className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                autoCorrect="off"
                type="email"
                inputMode="email"
                name="email"
                autoCapitalize="none"
                id="email"
                placeholder="yourEmail@gmail.com"
                required={true}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="italic" style={{ color: "red" }}>
                  {" "}
                  {formik.errors.email}{" "}
                </div>
              )}
            </div>

            {/* Informations sur l'adresse */}
            <div className="w-full my-5 border border-gray-300 rounded px-2">
              <div
                onClick={() => setShowAdressInfo(!showAdressInfo)}
                className="w-full py-4 flex cursor-pointer items-center justify-between"
              >
                <div>
                  <input
                    type="checkbox"
                    onChange={() => setShowAdressInfo(!showAdressInfo)}
                    checked={showAdressInfo}
                    className="mr-2 accent-gray-200 rounded cursor-pointer"
                  />
                  Shipping address
                </div>
                <div>
                  {showAdressInfo ? (
                    <FaAngleUp color="gray" className="inline" />
                  ) : (
                    <FaAngleDown color="gray" className="inline" />
                  )}
                </div>
              </div>
              {showAdressInfo && <ShippingAddress formik={formik} />}
            </div>

               {/* Container des boutons de payement */}
               <div className="mb-4">
              <div className="text-center">
                {/* Si la methode de payement est paypal */}
                {formik.values.paymentMethod === "WORLD_PAYPAL" ? (
                  // Bouton de paiement paypal
                  <PayPalScriptProvider options={paypalOptions}>
                    <PayPalButtons
                      style={{ layout: "horizontal" }}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: actualTotal,
                                currency_code: currency,
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order
                          .capture()
                          .then(function (details) {
                            toast.success(
                              `Payment processed successfully by ${details.payer.name.given_name} !`
                            );
                            setTimeout(() => {
                              window.location.href = data.success_url;
                            }, 7000);
                          })
                          .catch((error) => {
                            toast.error(
                              "An error occured while processing. Please try again",
                              {
                                position: toast.POSITION.TOP_CENTER,
                              }
                            );
                          });
                      }}
                      onError={(error) => {
                        console.log(error);
                        toast.error(
                          "An error occured while processing. Please try again",
                          {
                            position: toast.POSITION.TOP_CENTER,
                          }
                        );
                      }}
                      onCancel={() => {
                        toast.error("The transaction has been cancelled", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                ) : (
                  // Bouton de paiement normal (pour mobile money ou world Visa)
                  <button
                    disabled={isProcessing}
                    type="submit"
                    className={`appearance-none  ${
                      isProcessing ? "bg-orange-300" : "primary-bgcolor"
                    } rounded w-full py-4 px-3 leading-tight focus:outline-none focus:shadow-outline text-white hover:opacity-60`}
                  >
                    {isProcessing && (
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 mr-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                    {isProcessing ? "Loading..." : "Pay"}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </section>
  );
}
