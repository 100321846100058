import { toast } from 'react-toastify'

const menuVariants = {
  open: {
      display: 'initial',
      opacity: 1,
  },
  closed: {
      display: 'none',
      opacity: 0,
  }
}

const countryNames = {
  CM: "Cameroon",
  BF: "Burkina faso",
  GA: "Gabon",
  CI: "Ivory cost",
  NG: "Nigeria",
  SL: "Sierra Leone",
  EG: "Egypt",
  KE: "Kenya",
  GH: "Ghana",
  TZ: "Tanzania",
  SN: "Senegal",
  UG: "Uganda",
  ZM: "Zambia",
  RW: "Rwanda"
}

const regexProvider = {
  CM_MTNMOMO: /(:?^6(:?(:?7)(:?\d){7}$))|(:?^6(:?(:?5[0-4])(:?\d){6}$))|(:?^6(:?(:?8)(:?\d){7}$))/,
  CM_ORANGE: /(:?^6(:?(:?9)(:?\d){7}$))|(:?^6(:?(:?5[5-9])(:?\d){6}$))/,
  CI_MTN: /^(01|02|03|04|05)\d{7}$/,
  CI_ORANGE: /^(07|08|09)\d{7}$/,
  CI_MOOV: /^(01|05|07|08|40)\d{7}$/,
  SN_ORANGE: /^(76|77|78)\d{7}$/,
  SN_FREEMONEY: /^(76|77|78)\d{7}$/,
  SN_EMONEY: /^(76|77|78)\d{7}$/,
  RW_MTN: /^(78)\d{7}$/,
  RW_AIRTEL: /^(73)\d{7}$/,
  GA_MOOV: /(:?^0(:?(:?6)(:?\d){7}$))/,
  GA_AIRTEL: /(:?^0(:?(:?7)(:?\d){7}$))/,
  SL_ORANGE: /^(25|30|33|34|40|44|50|55|76|78|79)\d{6}$/,
  BF_ORANGE: /(:?(:?(([5 - 7][4 - 7])|(0[5 - 7])))(:?\d){6}$)/,
  KE_MPESA: /\d{9}$/,
  BF_MOBICASH: /(:?(:?7[0-3])(:?(:?0[0|5])|(:?1[0|9])|(:?2[0|2])|(:?9[0|1|9])|(:?4[0|5])|(:?5[0|5])|(:?60)|(:?81))(:?\d){4}$)/,
  TZ_AIRTEL: /(:?(78)(:?\d){7}$)/,
  TZ_TIGO: /(:?(?:(65)|(67)|(71))(:?\d){7}$)/,
  TZ_HALOPESA: /(:?(:?62)(:?\d){7}$)/,
  TZ_VODAFONE: /(:?(:?20)(:?\d){7}$)/,
  UG_VODAFONE: /\d{9}$/,
  GH_VODAFONE: /(:?(:?20)(:?\d){7}$)/,
  GH_MTN: /(:?(:?[2|5]4)(:?\d){7}$)/,
  ZM_AIRTEL: /(:?(:?9)(:?\d){8}$)/,
  ZM_MTN: /(:?(:?9)(:?\d){8}$)/,
  ZM_ZAMTEL: /(:?(:?9)(:?\d){8}$)/,
  GH_TIGO: /(:?(:?(26)|(27)|(57))(:?\d){7}$)/
}

const pspLogos = {
  CM_MTNMOMO: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  CM_ORANGE: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  BF_ORANGE: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  BF_MOBICASH: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mobicash.png",
  CI_MTN: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  CI_ORANGE: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  CI_MOOV: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/moov.png",
  GA_MOOV: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/moov.png",
  GA_AIRTEL: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  GH_MTN: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  GH_TIGO: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  GH_VODAFONE: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/vodafone.png",
  KE_MPESA: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mpesa.png",
  RW_AIRTEL: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  RW_MTN: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  SN_ORANGE: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  SN_FREEMONEY: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/free-money.png",
  SN_EMONEY: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/e-money.png",
  SL_ORANGE: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  TZ_AIRTEL: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  TZ_TIGO: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/tigo.png",
  TZ_HALOPESA: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/halopesa.png",
  TZ_VODAFONE: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/vodafone.png",
  UG_VODAFONE: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/vodafone.png",
  ZM_AIRTEL: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  ZM_MTN: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  ZM_ZAMTEL: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/zamtel.png",
  WORLD_PAYPAL: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/paypal.png", 
  WORLD_VISA: "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/cb.png"
}

const countryFlags = {}
// See https://flagpedia.net/download/api for country flags img
Object.keys(countryNames).map(country => {
  countryFlags[country] = `https://flagcdn.com/w20/${ country.toLowerCase() }.png`
  return true
})
  
const countryCurrencies = {
  CM: "XAF",
  GA: "XAF",
  BF: "XAF",
  CI: "XOF",
  SN: "XOF",
  SL: "SLE",
  KE: "KES",
  TZ: "TZS",
  UG: "UGX",
  GH: "GHS",
  ZM: "ZMW",
  RW: "RWF"
}

const worldCurrencies = ['USD', 'MAD', 'MZN', 'ZAR', 'NGN', 'EGP']

const getCountryValues = () => {

  const countries = Object.keys(countryNames).map(country => {
    return {  
      label: <><img src={countryFlags[country]} width="16" height="12" alt={ countryNames[country] } style={{ display: 'inline', marginRight: '5px' }} />{' '} { countryNames[country] }</>,
      value: country
    }
  })
  
  return countries

}

const countryValues = getCountryValues()

const errorHandler = (error, toToast=true) => {
    console.log('ERROR', error)

    const errorList = {
        ERR_NETWORK: 'Network error, please check your internet.',
        ERR_BAD_REQUEST: 'An error occured. Please check your informations and try again.',
        ERR_BAD_RESPONSE: 'Request failed: Server error. please try again.',
        ECONNABORTED: 'Error due to request timeout. Please check your internet and try again.',
        ENOTFOUND: 'Request failed: Server not found. Please try again.',
        ECONNREFUSED: 'Request failed: Access denied to the server.',
        ETIMEDOUT: 'Error due to request timeout. Please check your internet and try again.',
        EHOSTUNREACH: 'Request failed: Server unreachable. Please try again.',
        EPIPE: 'Request failed: Your internet is too slow or not working.',
        EAI_AGAIN: 'Request failed: DNS problem. Please try again.',
        EPROTO: 'Request failed: Protocol error. Please try again.',
        ENETUNREACH: 'Request failed: Network is unreachable. Please check your internet.',
        ERR_CONNECTION_TIMED_OUT: 'Request failed: The server took too long to respond. Please try again.',
        ERR_CONNECTION_REFUSED: 'Request failed: The server refused to connect. Please try again.',
        ERR_ADDRESS_UNREACHABLE: 'Request failed: Server address unreachable.',
        ERR_INTERNET_DISCONNECTED: 'Request failed: Internet connection was lost while processing. Please try again.',
        ERR_PROXY_CONNECTION_FAILED: 'Request failed: The connection to the proxy server failed. Please try again.',
        ERR_NAME_RESOLUTION_FAILED: 'Request failed: Domain name error.',
    }
    
    const message = errorList[error?.code] ?? 'An error occured. please try again' 

    console.log(error)

    if (toToast) {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      })
    }

    return message
}

  export { menuVariants, countryValues, countryFlags, countryNames, errorHandler, worldCurrencies, countryCurrencies, pspLogos, regexProvider }