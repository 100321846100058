import React from 'react'
import Footer from '../../components/Footer'
import Image_404_2 from './../../../assets/images/404/404_2.jpg'
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
  
  const navigate = useNavigate()
  
  return (
    <div>
      <div className='flex container pt-10 mx-auto text-sm'>
        <div className='mx-auto md-full w-full lg:w-10/12 rounded shadow-2xl my-5 bg-white py-5'>
            <FaArrowLeft color='gray' className='cursor-pointer mx-5' onClick={() => navigate(-1)} size={25} /> 
            <div>
                <img alt='404 error' className='mx-auto w-full md:w-1/2' src={ Image_404_2 } />
            </div>
            <div className='text-center py-5 text-xl px-1'>
              Page not found
            </div>   

        </div>
      </div>
      
      <Footer />
    </div>

  )
}


