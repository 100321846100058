import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import CheckoutService from '../../../services/checkout.service';
import { errorHandler } from '../../../utils';
import Spinner from '../../components/Spinner';
import VisaCards from '../../../assets/images/payment/visa-cards.png'
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuccessImage from './../../../assets/icons/success.png'
import FailedImage from './../../../assets/icons/failed.png'

const CheckStatus = () => {
  const {id} = useParams()
  const [ isLoading, setIsLoading ] = useState(true)  
  const [ isSuccess, setIsSuccess ] = useState(false)
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currency = useSelector((store) => store.datas.currency)
  const actualTotal = useSelector((store) => store.datas.actualTotal)

  const MAX_ATTEMPS = 7

  let actualAttemp = 0

  const redirect =(url)=>{
   return setTimeout(() => {                  
      window.location.href = url
    }, 7000);
  }

  const isMatch = (array, valToCheck) => {
    return array.every(function(item){
      return item === valToCheck;
    })
  }
  
  useEffect(() => {
    const callCheckStatus = (id) => {
    
      actualAttemp = actualAttemp + 1

      if(actualAttemp <= MAX_ATTEMPS) {
        
        CheckoutService.checkStatus(id).then(response => {
          const dataTocheck = [response?.data?.data?.transaction?.status, response?.data?.data?.status];
          const isSuccess = response?.data?.data?.transaction ? isMatch(dataTocheck,'SUCCESS') : response?.data?.data?.status === 'SUCCESS';
          const isFailed = response?.data?.data?.transaction ? isMatch(dataTocheck,'FAILED') : response?.data?.data?.status === 'FAILED';
          
          dispatch({ 
            type: 'SET_DATAS', 
            payload: { 
              currency: response?.data?.data?.currency,
              success_url: response?.data?.data?.success_url,
              cancel_url: response?.data?.data?.cancel_url,
              actualTotal: response?.data?.data?.total_amount
            }
          })

          if(isSuccess) {
            setIsSuccess(true)
            setIsLoading(false)
            redirect(response?.data?.data?.success_url)
          
          }else if(isFailed) {
            setIsSuccess(false)
            setIsLoading(false)
            redirect(response?.data?.data?.cancel_url)
          }else{
            setTimeout(()=>{
              callCheckStatus(id)
            }, 7000)
          }
        }).catch(error => {
          if(error?.response?.status === 404 || error?.response?.data?.statusCode === 404){
            navigate('/BadRequest', { replace: true, state: { message: error?.response?.data?.message  } })
          }else{
            errorHandler(error)
            setTimeout(()=>{
              callCheckStatus(id)
            }, 7000)
          }
        })
      }else{
        setIsLoading(false)
        setIsSuccess(false)
      }
    }
   callCheckStatus(id)
  },[id])
 
  const borderColor = (isLoading && !isSuccess) ? 'orange-400' : (isSuccess && !isLoading ? 'green-500' : 'red-500')
   
  return (
    <div className='main-container flex justify-center items-start bg-slate-200 pt-28 md:pt-48 px-2'>    
      <ToastContainer />
      <div 
      className={`flex flex-col items-center w-full sm:w-[700px] border-2 border-t-${borderColor} shadow-lg p-9 rounded-md text-center shadow-gray-300 bg-white`}>

        <div className='block'>
          <img src='/LogoPayunit.svg' />
        </div>

        {currency &&
          <div className='w-full'>
            <p className='mt-5 text-left text-xl text-gray-500'>
              Payment of <span className='font-bold'> { actualTotal } { currency }</span> 
            </p>
            <hr className='my-3' />
          </div>
        }
        {<img className='my-4' src={VisaCards}  />
        }
        { isLoading && !isSuccess ? 
          <Fragment>
            <h1 className='font-bold mb-4 mt-2 text-gray-500'>Checking payment status...</h1>
            <div>
              <Spinner  />
            </div>
          </Fragment>
        : 
          isSuccess && !isLoading ?
            <Fragment>
              <img alt='Success icon' className='mx-2' src='/paySuccess.png' />
              <p className='text-green-700 w-full flex justify-center items-center bg-green-100 p-4 rounded my-4'>
                <img alt='Success icon' className='mx-2' src={ SuccessImage } width={30} />
                Your payment processed successfully
              </p>
              <small className='text-gray-500 mt-3'>You will be redirected in few seconds...</small>

            </Fragment>
          : 
            <Fragment>
              <img alt='Failed icon' className='mx-2' src='/payFailed.png' />
              {!actualAttemp > MAX_ATTEMPS ? 
                <>
                  <p className='text-red-700 w-full flex justify-center items-center bg-red-200 p-4 rounded my-4'>
                    <img alt='Success icon' className='mx-2' src={ FailedImage } width={35} />
                    Your payment has failed or has been cancelled
                  </p>
                  <small className='text-gray-500 mt-3'>You will be redirected in few seconds...</small>
                </>
              :
                <>
                  <p className='text-red-700 w-full flex justify-center items-center bg-red-200 p-4 rounded my-4'>
                    <img alt='Success icon' className='mx-2' src={ FailedImage } width={35} />
                    Unable to get your transaction status for the moment. 
                  </p>
                  <small className='text-gray-500 mt-3'>Click on retry or contact the support on <span className='font-bold'><a href="mailto: info@payunit.net">info@payunit.net</a></span></small>
                  <button 
                    className="text-white hover:bg-gray-500 w-full primary-bgcolor font-medium rounded text-sm px-5 py-4 text-center mt-3"
                    onClick={() => window.location.href = ''}
                  >
                    Retry
                  </button>
                </>
              }
            </Fragment>
        }
      </div>
    </div>
  )
}

export default CheckStatus  