import React from 'react'
import { FaAngleDown, FaAngleUp, FaArrowLeft } from 'react-icons/fa'
import Product from '../Product'
import { motion } from "framer-motion";
import { menuVariants } from '../../../utils';
import { useSelector } from 'react-redux';
import { LoaderMobileProduct } from '../LoaderGroup';
import DefaultImage from '../../../assets/images/noImage.png'

export default function MobileCart({ mobileMenuOpen, isLoading, setMobileMenuOpen }) {
  const productList = useSelector((store) => store.datas.productList)
  const application = useSelector((store) => store.datas.application)
  const currency = useSelector((store) => store.datas.currency)
  const actualTotal = useSelector((store) => store.datas.actualTotal)
  const success_url = useSelector((store) => store.datas.success_url)
  
    const goBack = () => {
        const parserUrl = document.createElement('a')
        parserUrl.href = success_url
        
        const baseUrl = `${ parserUrl.protocol }//${ parserUrl.host }`
        window.location.href = baseUrl
    }

    return (
        <>
            <div className='relative lg:hidden mx-auto pt-10'>
                {/* Entete */}
                <div className="flex justify-between mx-auto px-6">
                    <div className='flex'>
                        { !isLoading &&
                            <FaArrowLeft color='gray' className='cursor-pointer' size={20} onClick={ goBack } />
                        } 
                        <span className='ml-3'>{ application?.name } </span>
                    </div>
                    <div className='underline text-gray-500 p-1 cursor-pointer' onClick={ () => setMobileMenuOpen(!mobileMenuOpen) }>
                        Details
                        <span className='ml-1'>
                        {
                            mobileMenuOpen ? <FaAngleUp className='inline' /> : <FaAngleDown className='inline' /> 
                        }
                        </span>
                    </div>
                </div>
                {/* Container des produits */}
                <motion.div animate={ mobileMenuOpen ? 'open' : 'closed' } variants={ menuVariants } className='w-full hidden shadow-2xl mx-auto absolute z-10 bg-white p-6'>
                    {
                        productList?.map((productItem) => {
                            return <Product product={ productItem } key={ productItem.id } />
                        })
                    }
                    {/* Prix total */}
                    <div className='w-full mt-6 flex'>
                        <div className='w-6/12'> Total amount </div>
                        <div className='w-6/12 text-2xl text-end font-bold'> { actualTotal } { currency }</div>
                    </div>
                </motion.div>
            </div>
        
            { (isLoading || productList?.length === 0) ? 
                <LoaderMobileProduct /> 
                :
                // Informations panier
                <div className='text-center transition-opacity lg:hidden my-10 justify-center'>
                    <div className='h-auto relative mx-auto w-4/12'>
                        <img className='mx-auto rounded w-full h-auto' src={ productList[ 0 ].image_url ?? DefaultImage } alt='product  '/>
                        <div className='mx-auto cursor-pointer px-5 shadow-xl border bottom-1 w-fit absolute inset-x-0 -mt-1  rounded-lg rounded-t-none" bg-white' onClick={ () => setMobileMenuOpen(!mobileMenuOpen) }>
                            { productList?.length } Items
                        </div>
                    </div>
                    <p className='mx-auto mt-4 text-gray-700'>
                        { application?.name }
                    </p>
                    <p className='mx-auto text-2xl font-bold'>
                        { actualTotal } { currency }
                    </p>
                </div>
            }
        </>
    )
}
