import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { LoaderProduct, LoaderTotal } from '../LoaderGroup'
import Product from '../Product'
import { useSelector } from 'react-redux'

export default function Cart({ isLoading }) {
    const productList = useSelector((store) => store.datas.productList)
    const application = useSelector((store) => store.datas.application)
    const currency = useSelector((store) => store.datas.currency)
    const actualTotal = useSelector((store) => store.datas.actualTotal)
    const success_url = useSelector((store) => store.datas.success_url)
  
    const goBack = () => {
        const parserUrl = document.createElement('a')
        parserUrl.href = success_url
        
        const baseUrl = `${ parserUrl.protocol }//${ parserUrl.host }`
        window.location.href = baseUrl
    }

  return (
    
    <div className='col-left flex hidden lg:block lg:justify-end p-12'>
        <div className='w-10/12'>
        {/* Entete */}
        <div className='flex'>
            { !isLoading &&
                <FaArrowLeft color='gray' className='cursor-pointer' size={20} onClick={ goBack } />
            } 
            <span className='ml-3'>{ application?.name } </span>
        </div>
        <p className='text-4xl font-bold mt-5'>{ (isLoading || productList?.length === 0) ? <span className='text-sm'>Loading...</span> : `${ actualTotal } ${ currency }` }</p>
        {/* Container des produits */}
        <div className='grid mt-8'>
            { (isLoading || productList?.length === 0) ? <LoaderProduct /> : 
                productList?.map((productItem) => {
                    return <Product product={ productItem } key = { productItem.id } />
                })
            }
        </div>

        { (isLoading || productList?.length === 0) ? <LoaderTotal /> :
        <>
            {/* Informations panier */}
            <hr className="sm:mx-auto border-gray-200 my-5" />
            <div className='flex justify-end'>
                <div className='w-9/12'>
                    <div className='w-full flex'>
                        <div className='w-6/12'> Total amount </div>
                        <div className='w-6/12 text-end font-bold text-lg'> { actualTotal } { currency } </div>
                    </div>
                </div>
            </div>
        </>
        }
        </div>
    </div>
  )
}
