import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { COUNTRIES } from '../../../utils/allCountries';
import CountrySelector from '../CountrySelect';

export default function ShippingMethod({ formik }) {
    const showPhoneNumber = useSelector((store) => store.configs.showPhoneNumber)
    const showAddress = useSelector((store) => store.configs.showAddress)

    const [ countryOpen, setCountryOpen ] = useState(false)

    const onCountryChange = (value) => {
        const selectedCountry = COUNTRIES.find((option) => option.value === value)
        formik.setFieldValue('addressCountry', selectedCountry)
    }
  return (
    
    <div className="mb-4">
        <input 
            className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            value={ formik.values.addressName }
            autoCorrect='off'
            type='text'
            name='addressName'
            autoCapitalize='none'
            id='addressName'
            placeholder="Your name"
            required={ false }
        />
        <CountrySelector
            id="addressCountry"
            open={ countryOpen }
            onToggle={() => setCountryOpen(!countryOpen)}
            onChange={(value) => onCountryChange(value)}
            selectedValue={ formik.values.addressCountry }
        />

        {
            showAddress &&

            <input 
                className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                onChange={ formik.handleChange }
                onBlur={ formik.handleBlur }
                value={ formik.values.address }
                autoCorrect='off'
                type='text'
                name='address'
                autoCapitalize='none'
                id='shipping-address'
                placeholder="Your address"
                required={ false }
            />
        }
        
        {
            showPhoneNumber &&

            <input 
                className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                onChange={ formik.handleChange }
                onBlur={ formik.handleBlur }
                value={ formik.values.phoneAddress }
                autoCorrect='off'
                type='tel'
                name='phoneAddress'
                autoCapitalize='none'
                id='shipping-phone'
                placeholder="+237 6 92 50 37 97"
                required={ false }
            />
        }
        
    </div>
  )
}


