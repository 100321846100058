import React, { useEffect, useState } from 'react'
import { countryCurrencies, countryValues, errorHandler, worldCurrencies } from '../../../utils'
import Footer from './../../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CheckoutService from '../../../services/checkout.service'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import Modal from '../../components/Modal'
import ModalPayment from '../../components/Modal/ModalPayment';
import MobileCart from '../../components/MobileCart';
import Cart from '../../components/Cart'
import PaymentForm from '../../components/PaymentForm'
import { COUNTRIES } from '../../../utils/allCountries'
import { paymentValidation } from '../../../utils/validations'
import ModalPaymentRedirect from '../../components/Modal/ModalPaymentRedirect'

export default function Checkout() {

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ mobileMenuOpen, setMobileMenuOpen ] = useState( false )
  const [ paymentSuccessed, setPaymentSuccessed ] = useState(false)
  const [ modalVisible, setModalVisible ] = useState(false)
  const [ modalPaymentVisible, setModalPaymentVisible ] = useState(false)
  const [ modalPaymentRedirectVisible, setModalPaymentRedirectVisible ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)  
  const [ isProcessing, setIsProcessing ] = useState(false)
  const [ loadingPsp , setLoadingPsp ] = useState(false) 
  const [ cardPaymentUrl , setCardPaymentUrl ] = useState("") 
  const [paymentInputs, setPaymentInputs] = useState([]);
  const providers = useSelector((store) => store.datas.providers);
  const success_url = useSelector((store) => store.datas.success_url);
  const cancel_url = useSelector((store) => store.datas.cancel_url);
  
  // Nombre maximal de tentative de verification du status de la transaction
  const MAX_ATTEMPS = 20
  // Nombre actuel de tentative de verification du status de la transaction
  let actualAttemp = 0

  // Fonction qui verifie si le lien a deja expiré
  const checkExpired = (error) => {
    const envCheckExpiredCondition = error?.response?.data?.status === 'FAILED'

    if (envCheckExpiredCondition) {
      navigate('/BadRequest', { replace: true, state: { message: error?.response?.data?.message  } })
      return true
    }
    return false
  }
    
  // changement de pays pour la methode de paiement
  const onPaymentCountryChange = (country, providerList = []) => {
    setLoadingPsp(true)
    formik.setFieldValue("country", country);
    const psps = (providers?.length) > 0 ? providers : providerList
    // tri tous les providers disponibles pour le pays selectionné et qui sont aussi disponibles pour le checkout (qui ont ete renvoyé par l'API)
    const receivedInputs = psps.filter((input) => {
      return (
        input.shortcode.split("_")[0] === country?.value ||
        input.shortcode === "WORLD_PAYPAL" ||
        input.shortcode === "WORLD_VISA"
      )
    })

    setPaymentInputs(receivedInputs)
    setTimeout(() => {
      setLoadingPsp(false)      
    }, 500)

  }

  const formik = useFormik({
    initialValues: {
      email: 'info@payunit.net',
      addressName: '',
      addressCountry: COUNTRIES.find((option) => option.value === 'CM'),
      country: { label: <span className='text-gray-600'>Select a country</span>, value: '' },
      address: '',
      phoneAddress: '',
      paymentMethod: '',
      phonePayment: '',
      cardNumber:'',  
      cvv:'',  
      expirationMonth:'',  
      expirationYear:'',  
      zipCode:'',
      cardCountry: { title: <span className='text-gray-600'>Select your card country</span>, value: '' },
      cardState: '',
      cardCity: '',
      cardAddress: '',
      card_payment_url: ''
    },
    validate: paymentValidation,
    onSubmit: values => {

      setIsProcessing(true)

      // Si il clique sur le button submit au lieu du button de paypal alors qu'il a choisi/coché paypal comme methode de paiement
      if (values?.paymentMethod === 'WORLD_PAYPAL') {
        toast.error('Click on the Paypal button to pay with Paypal !', {
          position: toast.POSITION.TOP_CENTER
        })
        setIsProcessing(false)
        return false
      }

      if (values?.paymentMethod === 'WORLD_VISA') {
        // Payload d'initialisation du paiement mobile money
        const datas = {
          checkout_id: id,
          customer: {
            email: values?.email,
            phone: values?.phoneAddress,
            country: values?.addressCountry?.title,
            name: values?.addressName
          },
          shipping: {
            address: values?.address,  
            phone: values?.phoneAddress,
            payment_method: values?.paymentMethod,
            payment_info: {
              card_info:{
                card_number: values?.cardNumber,
                cvv: values?.cvv,
                expiry_month: values?.expirationMonth < 10 ? `0${values?.expirationMonth}` : `${values?.expirationMonth}`,
                expiry_year: values?.expirationYear < 10 ? `0${values?.expirationYear}` : `${values?.expirationYear}`,
                card_country: values?.cardCountry?.value,
                card_state: values?.cardState,
                card_city: values?.cardCity,
                card_address: values?.cardAddress,
                card_zipcode: values?.zipCode,
              } 
            }
          }
        }
        
        CheckoutService.processPayment(datas).then(response => {
          if(response?.data?.statusCode === 200 || response?.data?.statusCode === 201){
            toast.warning("Payment in process...")
            setModalVisible(true)
            const payment_url = response?.data?.data?.transaction?.card_payment_url
            formik.setFieldValue('card_payment_url', payment_url)
            setTimeout(() => {
              window.location.href = payment_url 
            }, 5000)

          }else{
            setIsProcessing(false)
            toast.error('An error occured while processing. Please check your informations and try again.', {
              position: toast.POSITION.TOP_CENTER
            })
            console.log(response)
          }
        }).catch(error => {
          if(!checkExpired(error)){
            setModalVisible(false)
            setIsProcessing(false)
            errorHandler(error)
          }
        })
      
      }else{
        // Payload d'initialisation du paiement mobile money
        const datas = {
          checkout_id: id,
          customer: {
            email: values?.email,
            phone: values?.phoneAddress,
            country: values?.addressCountry?.title,
            name: values?.addressName
          },
          shipping: {
            address: values?.address,  
            phone: values?.phonePayment,
            payment_method: values?.paymentMethod,
            payment_info: {
              phone: values?.phonePayment
            }
          }
        }

        // Initialisation du paiement
        CheckoutService.processPayment(datas).then(response => {
          const statusCode = response?.data?.statusCode
          const paymentStatus = response?.data?.data?.status
          const transaction = response?.data?.data?.transaction
          const envProcessCondition = statusCode === 200 && paymentStatus === 'PENDING'
          
          // Ré initialisation du nombre de tentative de vérification du status
          actualAttemp = 0
          
          // Si le paiement a bien été initié
          if (envProcessCondition) {
            toast.warning("Payment in process...")
            // Si la clé card_payment_url existe (!==null) alors le paiement se fait avec un provider de type redirect
            if(transaction?.card_payment_url !== null) {
              // Assignation du lien du paiement
              setCardPaymentUrl(transaction?.card_payment_url)
              // Affichage de la modal de validation du paiement avec le provider de type redirect
              setModalPaymentRedirectVisible(true)
            }
            setModalVisible(true)
            setTimeout(() => {
              callCheckStatus(id)
            }, 30000)
  
          // Erreur d'initialisation du paiement
          }else{
            setIsProcessing(false)
            toast.error('An error occured while processing. Please check your informations and try again.', {
              position: toast.POSITION.TOP_CENTER
            })
            console.log(response)
          }
    
        }).catch(error => {
          // La transaction est déjà en pending et en attente de validation...
          if(error?.response?.data?.message === "Checkout payment pending") {
            toast.warning("Your checkout payment is actually pending...")
            setModalVisible(true)
            setTimeout(() => {
              callCheckStatus(id)
            }, 30000);

          // Une autre erreur est survenue
          }else{
            if(!checkExpired(error)){
              setModalVisible(false)
              setIsProcessing(false)
              setModalPaymentRedirectVisible(false)
              errorHandler(error)
            }
          }
        })
      }
    }
  })
  
  useEffect(() => {
    // Récupération des données du checkout
    CheckoutService.getCheckoutDetails(id).then(response => {

      // Vérifie si le lien a déjà expiré....
      if(response?.data?.data?.session === 'EXPIRED') navigate('/BadRequest', { replace: true })
  
      // Si la monaie est une monaie de bank (USD, MAD, MZN, ZAR etc...) on affiche uniquement paypal et bank.... sinon on affiche tous les provider qui correspondent à la monaie...
      const providerList = response?.data?.data?.merchant?.application?.psp?.filter((psp) => {
        return (
          worldCurrencies.find(currency => currency === response?.data?.data?.currency) ? 
            (psp.shortcode === 'WORLD_PAYPAL' || psp.shortcode === 'WORLD_VISA')
          : 
            (response?.data?.data?.currency === countryCurrencies[psp?.country?.country_code] || (psp.shortcode === 'WORLD_PAYPAL' || psp.shortcode === 'WORLD_VISA')) 
        )
      })

      console.log(providerList)

      // Enregistrement des données dans le store
      dispatch({ 
        type: 'SET_DATAS', 
        payload: { 
          productList: response?.data?.data?.products,
          application: response?.data?.data?.merchant?.application,
          currency: response?.data?.data?.currency,
          success_url: response?.data?.data?.success_url,
          cancel_url: response?.data?.data?.cancel_url,
          actualTotal: response?.data?.data?.total_amount,
          providers: providerList
        }
      })

      // Enregistrement des données de configuration dans le store
      dispatch({ 
        type: 'SET_CONFIGS', 
        payload: { 
          showPhoneNumber: response?.data?.data?.phone_number_collection,
          showAddress: response?.data?.data?.address_collection,
        }
      })

      // Verifie si il y'a un provider du cameroun dans la réponse et que la monaie est XAF
      const findDefaultCountry = response?.data?.data?.merchant?.application?.psp?.find(psp => psp?.country?.country_code === 'CM')
      // Si il y'a un provider du cameroun, on met Cameroon par defaut pour la valeur country
      if(findDefaultCountry && response?.data?.data?.currency === 'XAF'){
        onPaymentCountryChange(countryValues?.find(country => country.value === 'CM'), providerList)
      }

      setIsLoading(false)          

    }).catch(error => {
      if(!checkExpired(error)) {
        navigate('/BadRequest', { replace: true, state: { message: errorHandler(error, false) } })
      }
    })
  }, [])

  const isMatch = (array, valToCheck) => {
    return array.every(function(item){
      return item === valToCheck;
    })
  }

  // Fonction de verification du status de la transaction
  function callCheckStatus (id) {
    
    actualAttemp = actualAttemp + 1

    if(actualAttemp <= MAX_ATTEMPS) {

      CheckoutService.checkStatus(id).then(response => {
        const dataTocheck = [response?.data?.data?.transaction?.status, response?.data?.data?.status];
        const isSuccess = response?.data?.data?.transaction ? isMatch(dataTocheck,'SUCCESS') : response?.data?.data?.status === 'SUCCESS';
        const isFailed = response?.data?.data?.transaction ? isMatch(dataTocheck,'FAILED') : response?.data?.data?.status === 'FAILED';
        
        const envCheckStatusConditionSuccess = response?.data?.statusCode === 200 && isSuccess
        const envCheckStatusConditionFailed = response?.data?.statusCode === 200 && isFailed
        
        // Si le user a validé le paiement
        if (envCheckStatusConditionSuccess) {
          formik.resetForm()
          setModalVisible(false)
          setModalPaymentRedirectVisible(false)
          setIsProcessing(false)
          setPaymentSuccessed(true)
          toast.success("Payment processed successfully !")
          setModalPaymentVisible(true)
          
          // Redirection à la page de succcès
          setTimeout(() => {                  
            window.location.href = response?.data?.data?.success_url ?? success_url
          }, 7000)

        // Si la transaction est annulée ou échouée
        } else if (envCheckStatusConditionFailed) {
          // Affichage de la modal d'echec
          setModalVisible(false)
          setModalPaymentRedirectVisible(false)
          setIsProcessing(false)
          setPaymentSuccessed(false)
          setModalPaymentVisible(true)

          toast.error('Payment failed or has been cancelled !', {
            position: toast.POSITION.TOP_CENTER
          })

          // Redirection à la page d'échec
          setTimeout(() => {                  
            window.location.href = response?.data?.data?.cancel_url ?? cancel_url
          }, 7000);
        
          // En entente de validation, on relance la vérification
        } else {
          setTimeout(() => {
            callCheckStatus(id)
          }, 5000);
        }
      }).catch(error => {
        
        // Si la tentative de vérification échoue à cause d'un problème de connexion
        if(error?.code === 'ERR_NETWORK') {
          setTimeout(() => {
            callCheckStatus(id)
          }, 5000);
        
        // Si une autre erreur survient
        }else{
          setModalVisible(false)
          setIsProcessing(false)
          setModalPaymentRedirectVisible(false)
          errorHandler(error)
        }        
      })
    }else{
      // Affichage de la modal d'echec
      setModalVisible(false)
      setModalPaymentRedirectVisible(false)
      setIsProcessing(false)
      setPaymentSuccessed(false)
      setModalPaymentVisible(true)

      toast.error('Payment failed or has been cancelled !', {
        position: toast.POSITION.TOP_CENTER
      })

      // Redirection à la page d'échec
      setTimeout(() => {                  
        window.location.href = cancel_url
      }, 7000);
    }
  }

  return (
    <div className='main-container'>
      <ToastContainer />
      {/* Modal de chargement pendant le paiement */}
      <Modal 
        modalVisible = { modalVisible } 
        setModalVisible = { setModalVisible }
        formik = { formik }
      />
      {/* Modal de validation du paiement pour les providers de type redirect */}
      <ModalPaymentRedirect 
        modalPaymentRedirectVisible={modalPaymentRedirectVisible}
        setModalPaymentRedirectVisible={setModalPaymentRedirectVisible}
        paymentUrl={cardPaymentUrl}
      />
      {/* Modal d'affichage de status après le paiement */}
      <ModalPayment
        modalPaymentVisible ={ modalPaymentVisible }
        paymentSuccessed ={ paymentSuccessed }
        setModalPaymentVisible = { setModalPaymentVisible }
      />
      <section className='flex container md:pt-10 mx-auto text-sm'>
        <div className='mx-auto md-full w-full lg:w-10/12 rounded lg:grid lg:grid-cols-2 md:shadow-2xl md:my-5 bg-white'>
          {/* Vue mobile du panier */}
          <MobileCart 
            mobileMenuOpen={ mobileMenuOpen }
            setMobileMenuOpen={ setMobileMenuOpen }
            isLoading = { isLoading } 
          />
          {/* Vue large ecran du panier */}
          <Cart isLoading = { isLoading } />
          
          {/* Formulaire de paiement */}
          <PaymentForm 
            formik={ formik }
            isLoading={ isLoading } 
            isProcessing={ isProcessing }
            loadingPsp={ loadingPsp }
            paymentInputs={ paymentInputs }
            onPaymentCountryChange={ onPaymentCountryChange }
          />
        </div>
      </section>

      <Footer />
    </div>
  )
}