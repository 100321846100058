// ** Router Components
import { BrowserRouter as AppRouter, Route, Routes } from 'react-router-dom'
import React from 'react';
import Checkout  from './../views/pages/Checkout'
import NotFound from '../views/pages/NotFound';
import BadRequest from '../views/pages/BadRequest';
import CheckStatus from '../views/pages/CheckStatus';

const Router = () => {

  return (
    <AppRouter>
      <Routes>

        <Route path='/' >
          <Route path='/' element={ <NotFound /> } />
          <Route path=':id' element={ <Checkout /> } />
          <Route path='/check-status/:id' element={ <CheckStatus /> } />
        </Route>

        <Route path='/BadRequest' element={ <BadRequest /> } />

        <Route path='*' element={ <NotFound /> } />
      
      </Routes>

    </AppRouter>
  )
}


export default Router