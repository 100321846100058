import React from 'react'
import SuccessImage from './../../../assets/icons/success.png'
import FailedImage from './../../../assets/icons/failed.png'
import { useSelector } from 'react-redux'

export default function ModalPayment({ modalPaymentVisible, paymentSuccessed, setModalPaymentVisible }) {

    const application = useSelector((store) => store.datas.application)
    const currency = useSelector((store) => store.datas.currency)
    const actualTotal = useSelector((store) => store.datas.actualTotal)

    return(
        <>
            { modalPaymentVisible &&
                // background noir transparent
                <div className='fixed w-full h-full z-40' style={{ background: 'black', opacity: 0.4 }}></div>
            }
            <div id="popup-modal" tabIndex="-1" style={{ backdropFilter: 'blur(2px)' }} className={`z-50 w-full fixed ${ modalPaymentVisible === false && 'hidden' } overflow-x-hidden overflow-y-auto h-[calc(100%-1rem)] md:h-full`}>
                <div className="flex w-full items-center h-screen h-full max-w-md md:h-auto m-auto" style={{ height: '100%' }}>
                    <div className="relative w-full bg-white rounded-lg shadow">
                        {/* Bouton de fermeture de la modal */}
                        <button type="button" onClick={() => setModalPaymentVisible(false)} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-hide="popup-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* Contenu de la modal */}
                        <div className="p-6 text-center">
                            <h3 className="mb-4 text-lg font-normal text-gray-500">
                                <p className='mt-5'>
                                    Payment of <span className='font-bold'> { actualTotal } { currency }</span> 
                                    {' '} for {' '}<span className='font-bold'> { application?.name } </span>. 
                                </p>
                                <hr className='my-2' />
                                {/* Message de succes ou d'echec en fonction du status */}
                                {paymentSuccessed ?
                                    <p className='text-green-700 flex justify-center items-center bg-green-100 p-4 rounded'>
                                        <img alt='Success icon' className='mx-auto' src={ paymentSuccessed ? SuccessImage : FailedImage } width={30} />
                                        Your payment processed successfully
                                    </p>
                                :
                                    <p className='text-red-700 flex justify-center items-center bg-red-200 p-4 rounded'>
                                        <img alt='Success icon' className='mx-auto' src={ paymentSuccessed ? SuccessImage : FailedImage } width={40} />
                                        Your payment has failed or has been cancelled
                                    </p>
                                }
                                
                            </h3>
                            <p className='mb-4 text-gray-500'>You will be redirected in few seconds...</p>
                            <button onClick={() => setModalPaymentVisible(false)} data-modal-hide="popup-modal" type="button" className="w-full text-white primary-bgcolor hover:bg-gray-500 font-medium rounded text-sm items-center py-2.5 text-center mr-2">
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}