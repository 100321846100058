import { React } from 'react'
import { useSelector } from 'react-redux'
import DefaultImage from '../../../assets/images/noImage.png'

export default function Product( props ) {
    const currency = useSelector((store) => store.datas.currency)

    return (
        <div className='product w-full min-h-fit mt-4 flex'>
            <div className='w-1/12 relative'>
                <div className='w-full h-12'>
                    <img className='w-full max-h-full mx-auto rounded' src={ props.product.image_url ?? DefaultImage } alt='img product' />
                </div>
            </div>

            <div className='w-7/12 grid grid-rows-2 pl-3'>
                <div>
                    { props.product.name }
                </div>
                <div>
                    <label className='text-xs text-slate-500' htmlFor={ `quantity-product-${ props.product.id }` }>Qty: </label>
                    <span> { props.product.quantity } </span> 
                </div>
            </div>
            <div className='w-4/12 text-end grid grid-rows-2 pl-3'>
                <div className=' font-bold'>
                    { props.product.amount * props.product.quantity } { currency }
                </div>
                <div className='text-slate-500 text-xs'>
                    { props.product.amount } { currency } / unit
                </div>
            </div>

        </div>
    )
}


