import { dataReducer } from "./dataReducer"
import { configReducer } from "./configReducer"

import { combineReducers } from 'redux'
import { configureStore } from "@reduxjs/toolkit"

const rootReducers = combineReducers({
    datas: dataReducer,
    configs: configReducer,
})


export const store = configureStore({
    reducer: rootReducers
})