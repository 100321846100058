import React from 'react'
import Footer from '../../components/Footer'
import Image_400 from './../../../assets/images/404/404.jpg'
import { FaArrowLeft } from "react-icons/fa"
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

export default function BadRequest() {
  
  const navigate = useNavigate()
  const location = useLocation()
  const message = location.state?.message

  setTimeout(() => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    })
  }, 1000);
  
  return (
    <div>
      <ToastContainer />
      <div className='flex container pt-10 mx-auto text-sm'>
        <div className='mx-auto md-full w-full lg:w-10/12 rounded shadow-2xl my-5 bg-white py-5'>
            <FaArrowLeft color='gray' className='cursor-pointer mx-5' onClick={() => navigate(-1)} size={25} /> 
            <div>
                <img alt='404 error' className='mx-auto w-full md:w-1/2' src={ Image_400 } />
            </div>
            <div className='text-center py-5 text-lg px-1'>
              { message ?? "The link may be expired or the shipping isn't available for this link" }
            </div>   

        </div>
      </div>
      
      <Footer />
    </div>

  )
}


