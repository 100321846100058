
const initialState = {
  productList: [],
  application: '',
  currency: '',
  success_url: '',
  cancel_url: '',
  actualTotal: 0,
  providers: []
}

// const removeProduct = (state, product) => {
//   if (product) {

//     const products =[...state?.productList]

//     // Suppression du produit dans productList
//     const remainingProducts = products?.filter(( storeProduct ) => parseInt( storeProduct?.id ) !== parseInt( product?.id ))

//     return {
//       ...state,
//       productList: remainingProducts
//     }
//   }
// }

// const updateProduct = (state, payload) => {
//   if (payload?.fields) {
//     const products = [...state?.productList]
    
//     const updatedProducts = products.map((product) => {
//       if (parseInt(product?.id) === parseInt(payload?.id)) {
//         return {
//           ...product,
//           ...payload.fields,
//         }
//       }
//       return product
//     })

//     return {
//       ...state,
//       productList: updatedProducts,
//     }
//   }
// }

// REDUCER
const dataReducer = (state = initialState, action) => {

  const actions = {

    SET_DATAS: {
      ...state,
      productList: action.payload?.productList,
      application: action.payload?.application,
      currency: action.payload?.currency,
      success_url: action.payload?.success_url,
      cancel_url: action.payload?.cancel_url,
      actualTotal: action.payload?.actualTotal,
      providers: action.payload?.providers
    },

    // REMOVE_PRODUCT: removeProduct(state, action.payload?.product),

    // UPDATE_PRODUCT: updateProduct(state, action.payload)
  }
  
  return actions[action.type] ?? state
} 

export { dataReducer }