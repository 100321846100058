import { regexProvider } from ".";

function checkValidNumber (phoneNumber, paymentMethod) {
  if(regexProvider[paymentMethod]) {
    return regexProvider[paymentMethod].test(phoneNumber)
  }
  return true;

}

const paymentValidation = values => {
    const errors = {}
    
    // if (!values.country || values.country?.value === '') {
    //   errors.country = 'Choose your country.'
    // }

    if (!values.paymentMethod) {
      errors.paymentMethod = 'Choose a payment method.'
    }

    if (!values.email) {
      errors.email = 'Enter your email.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email.'
    }

    // Si le moyen de paiement est mobile money
    if(values.paymentMethod !== 'WORLD_PAYPAL' && values.paymentMethod !== 'WORLD_VISA'){
      if (!values.phonePayment) {
        errors.phonePayment = 'Enter your payment phone number.'
      } else if (!checkValidNumber(values.phonePayment, values.paymentMethod)) {
        errors.phonePayment = 'Invalid phone number for your country.';
      }
    }

    // Si le moyen de paiement est par carte bancaire
    if(values.paymentMethod === 'WORLD_VISA'){
      if (!values.cardNumber) {
        errors.cardNumber = 'Please, fill in your card number.'
      }
      
      if (!values.zipCode) {
        errors.zipCode = 'Please, fill in your zip code.'
      }
    
      if (!values.cvv) {
        errors.cvv = 'Please, fill in your card CVV.'
      }else if(values.cvv.length !== 3){
        errors.cvv = 'Invalid CVV format.'
      }
    
      if (!values.expirationMonth || !values.expirationYear) {
        errors.expirationMonth = 'Fill in your card expiration date.'
      }else if(values.expirationMonth < 1 || values.expirationMonth > 12) {
        errors.expirationMonth = `Please enter a valid month value.`
      }

      if (!values.cardCountry || values.cardCountry?.value === '') {
        errors.cardCountry = 'Please, select your card country.'
      }

      if (!values.cardState) {
        errors.cardState = 'Please, fill in your card state.'
      }

      if (!values.cardCity) {
        errors.cardCity = 'Please, fill in your card city.'
      }

      if (!values.cardAddress) {
        errors.cardAddress = 'Please, fill in your card address.'
      }
    }

    return errors
  }


  export { paymentValidation }