import React from 'react'

export default function ModalPaymentRedirect({ modalPaymentRedirectVisible, setModalPaymentRedirectVisible, paymentUrl }) {

    return(
        <>
            { modalPaymentRedirectVisible &&
                // background noir transparent
                <div className='fixed w-full h-full z-40' style={{ background: 'black', opacity: 0.4 }} />
            }

            <div id="popup-modal" tabIndex="-1" style={{ backdropFilter: 'blur(2px)' }} className={`z-50 w-full fixed ${ modalPaymentRedirectVisible === false && 'hidden' } overflow-x-hidden overflow-y-auto h-[calc(100%-1rem)] md:h-full`}>
                <div className="flex w-full md:w-3/4 items-center h-screen m-auto">
                    <div className="relative w-full h-4/5 md:h-3/4 bg-white rounded-lg shadow">
                        {/* Bouton de fermeture de la modal */}
                        <button type="button" onClick={ () => setModalPaymentRedirectVisible(false) } className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-hide="popup-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* Contenu de la modal */}
                        <div className="p-6 mt-3 h-5/6 text-center">
                            {/* Iframe qui contient la page du payment URL pour le provider de type redirect */}
                            <iframe title='Payment processing' src={ paymentUrl } className='w-full h-3/4 md:h-full'></iframe>

                            <button onClick={ () => setModalPaymentRedirectVisible(false) } data-modal-hide="popup-modal" type="button" className="w-1/2 text-white hover:bg-gray-500 primary-bgcolor font-medium rounded text-sm items-center py-2.5 text-center mr-2">
                                Go back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}