
const initialState = {
  showPhoneNumber: false,
  showAddress: false
}

// REDUCER
const configReducer = (state = initialState, action) => {

    const actions = {
        
        SET_CONFIGS: {
            ...state,
            showPhoneNumber: action.payload?.showPhoneNumber,
            showAddress: action.payload?.showAddress,
        },
    }
    
    return actions[action.type] ?? state
}   

export { configReducer }