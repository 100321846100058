import React from 'react';

export default function PaymentInput( props ) {

  return (
    <label className='cursor-pointer'>
      <div className="payment-input block rounded md:rounded-lg p-2 bg-white relative ring-1 ring-slate-900/5">
          <div className='h-fit-content right-1 absolute md:right-2 top-1'>
            <input 
              className='w-3 h-3 md:w-4 md:h-4'
              type='radio' 
              value = { props.payment.shortcode } 
              name = 'paymentMethod'
              required = { true }
              onClick = { () => props.formik.setFieldValue("paymentMethod", props.payment.shortcode)}

            />
          </div>
          <div className="w-full h-full flex space-x-3 p-0">
            <img
              className='min-w-sm w-full rounded md:mx-auto'
              src = { props.payment.logo } 
              alt = { props.payment.name }
              style={{ objectFit: 'cover', maxHeight: '80px' }}
            />
          </div>
      </div>
    </label>
  )
}


