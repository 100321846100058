const LoaderProduct = () => {
    return (
        <div>
            <div className="shadow rounded p-4 w-full mx-auto my-4">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-300 h-10 w-10"></div>
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow rounded p-4 w-full mx-auto my-4">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-300 h-10 w-10"></div>
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const LoaderMobileProduct = () => {
    return (
        <div className="shadow lg:hidden rounded p-4 w-full mx-auto my-4">
            <div className="animate-pulse justify-center w-full space-x-4">
                <div>
                    <div className="rounded bg-gray-300 h-40 w-4/12 mx-auto"></div>
                    <div className="rounded bg-gray-300 h-5 mt-3 w-2/12 mx-auto"></div>
                    <div className="rounded bg-gray-300 h-5 mt-3 w-3/12 mx-auto"></div>
                </div>
            </div>
        </div>
    )
}

const LoaderTotal = () => {
    return (
        <div>
            <div className='flex justify-end'>
                <div className="rounded p-4 w-9/12">
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                            <div className="space-y-3">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                </div>

                                <div className="h-5 bg-gray-300 rounded"></div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='flex justify-end'>
                <div className="rounded p-4 w-9/12">
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                            <div className="space-y-3">

                                <div className="h-2 bg-gray-300 rounded"></div>
                                
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-end'>
                <div className="rounded p-4 w-9/12">
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                            <div className="space-y-3">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                </div>

                                <div className="h-3 bg-gray-300 rounded"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}


const LoaderShipping = () => {
    return (
        <div>
            <div className="rounded p-4 w-full">
                <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="h-2 bg-gray-300 w-4/12 rounded col-span-1"></div>
                            </div>

                            <div className="h-8 bg-gray-300 rounded"></div>
                        </div>
                        <div className="space-y-3">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="h-2 bg-gray-300 w-6/12 rounded col-span-1"></div>
                            </div>

                            <div className="h-8 bg-gray-300 rounded"></div>
                            <div className="h-8 bg-gray-300 rounded"></div>
                            <div className="h-8 bg-gray-300 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rounded p-4 w-full">
                <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="h-2 bg-gray-300 w-4/12 rounded col-span-1"></div>
                            </div>

                            <div className="h-8 bg-gray-300 rounded"></div>
                        </div>
                        <div className="space-y-3">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="h-2 bg-gray-300 w-6/12 rounded col-span-1"></div>
                            </div>

                            <div className="h-8 bg-gray-300 rounded"></div>
                            <div className="h-8 bg-gray-300 rounded"></div>
                            <div className="h-8 bg-gray-300 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export { LoaderProduct, LoaderMobileProduct, LoaderShipping, LoaderTotal }