import axios from "axios"
import { BASE_API_URL, headers } from "../configs"

export default class CheckoutService {

    static getCheckoutDetails = (id) => {
        const encodedID = encodeURIComponent(id)
        return axios.get(`${ BASE_API_URL }/detail/${ encodedID }`, { headers, timeout: null })
    }

    static processPayment = (data) => {
        return axios.post(`${ BASE_API_URL }/process`, data, { headers, timeout: null })
    }

    static checkStatus = (id) => {
        const encodedID = encodeURIComponent(id)
        return axios.get(`${ BASE_API_URL }/status/${ encodedID }`, { headers, timeout: null })
    }
}